import axios from "axios"
import { toast } from "react-hot-toast"

const API_HOST = "https://fancy-bush-27358.botics.co"

const doFetch = async (url, params, hasFile = false) => {
  const token =
    localStorage.getItem("hospice:auth") ||
    sessionStorage.getItem("hospice:auth")
  // console.log(token)
  let headers = {
    Accept: "application/json, text/plain, */*",
    "Content-Type": hasFile ? "multipart/form-data" : "application/json"
  }
  // console.log("=====token===", headers)
  if (token && !url.includes("sign-in") && !url.includes("sign-up")) {
    // console.log("=====token true===", token)
    headers.Authorization = `Token ${token}`
  }

  return fetch(API_HOST + url, {
    headers,
    ...params
  }).then(async response => {
    // console.log("====Response=====", response)
    if (response.ok) {
      return response.text().then(function (text) {
        return text ? JSON.parse(text) : {}
      })
    } else {
      const errorMessage = await response.text()
      const error = await handleError(JSON.parse(errorMessage))
      if (error.includes("Invalid token.")) {
        return
      }
      // console.log("=======Api error======", error)
      return Promise.reject(error)
    }
  })
}

export const handleError = error => {
  const errorKeys = Object.keys(error)
  if (errorKeys.includes("non_field_errors")) {
    return error.non_field_errors && error.non_field_errors[0]
  }
  if (errorKeys.includes("email")) {
    return error.email[0] || error.email.email[0]
  }
  const firstKey = errorKeys[0]
  return `${error[firstKey]}`
}

export const loginApi = params => {
  // console.log(params)
  return doFetch("/api/v1/login/ ", {
    method: "POST",
    body: JSON.stringify(params)
  })
}

export const registerApi = params => {
  return doFetch("/api/v1/admin-signup/", {
    method: "POST",
    body: JSON.stringify(params)
  })
}

export const forgotPasswordApi = params => {
  return doFetch("/api/v1/password_reset/", {
    method: "POST",
    body: JSON.stringify(params)
  })
}

export const resetPasswordApi = params => {
  return doFetch("/api/v1/password_reset/confirm/", {
    method: "POST",
    body: JSON.stringify(params)
  })
}

export const getUsers = () => {
  return doFetch("/api/v1/admin-user/")
}

export const deleteUser = id => {
  return doFetch("/api/v1/admin-user/" + id + "/", {
    method: "DELETE"
  })
}

export const getEmails = () => {
  return doFetch("/api/v1/email_request/")
}

export const getNurses = () => {
  return doFetch("/api/v1/nurse/")
}

export const getHowTos = () => {
  return doFetch("/api/v1/how-to/")
}

export const getEducation = () => {
  return doFetch("/api/v1/education/")
}

export const getEducationItem = id => {
  return doFetch("/api/v1/education/" + id + "/")
}

export const getHowToItem = id => {
  return doFetch("/api/v1/how-to/" + id + "/")
}

export const generateNurse = params => {
  return doFetch("/api/v1/nurse/", {
    method: "POST",
    body: JSON.stringify(params)
  })
}

export const getCompany = () => {
  return doFetch("/api/v1/company/")
}

export const generateHospice = params => {
  return doFetch("/api/v1/company/", {
    method: "POST",
    body: JSON.stringify(params)
  })
}

export const getUser = id => {
  return doFetch("/api/v1/admin-user/" + id + "/")
}

export const updateUser = params => {
  const {
    id,
    email,
    name,
    patient_name,
    phone_number,
    profile_picture,
    dob
  } = params

  var FormData = require("form-data")

  var data = new FormData()
  data.append("email", email)
  data.append("name", name)
  data.append("patient_name", patient_name)
  data.append("phone_number", phone_number)
  data.append("dob", dob)

  if (profile_picture) {
    data.append("profile_picture", profile_picture)
  }

  const token =
    localStorage.getItem("hospice:auth") ||
    sessionStorage.getItem("hospice:auth")

  var config = {
    method: "patch",
    url: "https://fancy-bush-27358.botics.co/api/v1/admin-user/" + id + "/",
    headers: {
      Authorization: "token " + token,
      "Content-Type": "multipart/form-data"
    },
    data: data
  }

  axios(config)
    .then(function (response) {
      toast.success("Profile Updated succesfully")

      // console.log(JSON.stringify(response.data))
      return response
    })
    .catch(function (error) {
      // console.log(error)
      return error
    })
}

export const addHowTo = params => {
  const { title, description, file } = params

  var FormData = require("form-data")

  var data = new FormData()
  data.append("title", title)
  data.append("description", description)
  if (file && file != "") {
    data.append("file", file)
  }

  const token =
    localStorage.getItem("hospice:auth") ||
    sessionStorage.getItem("hospice:auth")

  var config = {
    method: "post",
    url: "https://fancy-bush-27358.botics.co/api/v1/how-to/",
    headers: {
      Authorization: "token " + token,
      "Content-Type": "multipart/form-data"
    },
    data: data
  }

  axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data))
      toast.success("HowTo Added succesfully")

      return response
    })
    .catch(function (error) {
      // console.log(error)
      return error
    })
}

export const addEducation = params => {
  const { title, description, file } = params

  var FormData = require("form-data")

  var data = new FormData()
  data.append("title", title)
  data.append("description", description)
  if (file && file != "") {
    data.append("file", file)
  }

  const token =
    localStorage.getItem("hospice:auth") ||
    sessionStorage.getItem("hospice:auth")

  var config = {
    method: "post",
    url: "https://fancy-bush-27358.botics.co/api/v1/education/",
    headers: {
      Authorization: "token " + token,
      "Content-Type": "multipart/form-data"
    },
    data: data
  }

  axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data))
      toast.success("Education Added succesfully")

      return response
    })
    .catch(function (error) {
      // console.log(error)
      return error
    })
}

export const updateMailReq = (id, params) => {
  return doFetch("/api/v1/email_request/" + id + "/", {
    method: "PATCH",
    body: JSON.stringify(params)
  })
}

export const deleteEducation = id => {
  return doFetch("/api/v1/education/" + id + "/", {
    method: "DELETE"
  })
}

export const deleteHowTo = id => {
  return doFetch("/api/v1/how-to/" + id + "/", {
    method: "DELETE"
  })
}

export const updateEducation = params => {
  const { id, title, description, file } = params

  var FormData = require("form-data")

  var data = new FormData()
  data.append("title", title)
  data.append("description", description)

  if (file) {
    data.append("file", file)
  }

  const token =
    localStorage.getItem("hospice:auth") ||
    sessionStorage.getItem("hospice:auth")

  var config = {
    method: "patch",
    url: "https://fancy-bush-27358.botics.co/api/v1/education/" + id + "/",
    headers: {
      Authorization: "token " + token,
      "Content-Type": "multipart/form-data"
    },
    data: data
  }

  axios(config)
    .then(function (response) {
      toast.success("Education updated succesfully")

      // console.log(JSON.stringify(response.data))
      return response
    })
    .catch(function (error) {
      // console.log(error)
      return error
    })
}

export const updateHowTo = params => {
  const { id, title, description, file } = params

  var FormData = require("form-data")

  var data = new FormData()
  data.append("title", title)
  data.append("description", description)

  if (file) {
    data.append("file", file)
  }

  const token =
    localStorage.getItem("hospice:auth") ||
    sessionStorage.getItem("hospice:auth")

  var config = {
    method: "patch",
    url: "https://fancy-bush-27358.botics.co/api/v1/how-to/" + id + "/",
    headers: {
      Authorization: "token " + token,
      "Content-Type": "multipart/form-data"
    },
    data: data
  }

  axios(config)
    .then(function (response) {
      toast.success("HowTo updated succesfully")

      // console.log(JSON.stringify(response.data))
      return response
    })
    .catch(function (error) {
      // console.log(error)
      return error
    })
}